// Import our custom CSS
import '../scss/styles.scss'

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

window.onload = function() {
    $("body").removeClass('d-none');
}

$(window).scroll(function (event) {
    var h = $(window).scrollTop();
    var w = window.innerWidth;
    if(h > 45){
        if(w > 767){
            $('#nav-head').addClass('scroll-top')
        }
    }else{
        $('#nav-head').removeClass('scroll-top')
    }
})

$(document).ready(function(){
    $("#service-slider").owlCarousel({
        items:3,
        itemsDesktop:[1000,2],
        itemsDesktopSmall:[979,2],
        itemsTablet:[768,1],
        pagination:true,
        margin:15,
        navigation:false,
        navigationText:["<",">"],
        autoPlay:true
    });
});

$(document).ready(function(){
    $("#testimonial-slider").owlCarousel({
        items:2,
        itemsDesktop:[1000,2],
        itemsDesktopSmall:[979,2],
        itemsTablet:[768,1],
        pagination:false,
        navigation:true,
        navigationText:["",""],
        autoPlay:true
    });
});